
@media screen and (min-width: 768px) {
.main {
  width: 100%;
}
.header{
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.header_a{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,.6);
}
.header_b{
  width: 100%;
  padding: 0 40px;
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header_img{
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
.header_img img{
  width: 100vw;
  height: 100vh;
  object-fit:fill;
}
.header_video{
  position: relative;
  width: 100%;
  height: 100%;
  /* height: 100%; */
  /* width:100%;
  height: 100%; */
  transition-duration: .3s;
  transition-property: opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  opacity:1;
  object-fit:cover;
  max-width:100vw;
  vertical-align:middle;
}

.header .tabContainer {
  display: flex;
  flex-direction: row;
}
.header .name{
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  
}

.header .tab{
  width: 105px;
 
  /* background-color: #eee; */
  text-align: center;
  border-radius: 8px;
  /* background-color: #000; */
  border: 1px solid #fff;
  overflow: hidden;
}
.header .tab a{
  display: inline-block;
   padding: 8px 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border-bottom: none;
  text-decoration:none;
}

.header .tab a:hover{
  background-color: #fff;
  color: #000;
}

.header .tab:first-child{
  margin-right: 5px;
}

.see_b{
  /* min-width: 1440px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
}

.intro{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  color: #fff;

}
.see_t{
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  color: #fff;
  margin-bottom: 10px;
}
.see_tb{
  font-weight: 800;
  font-size: 52px;
  line-height: 56px;
  /* color: #4568DC; */
  background: linear-gradient(to right, rgb(80, 107, 193), hsl(298, 27%, 50%));
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
}
.enjoy_t{
  margin-top: 60px;
  color: #fff;
  width: 800px;
  line-height: 40px;
  text-align: center;
  word-break:break-word;
}
.enjoy_tb{
  margin-top: 6px;
  color: #fff;
}

.enter{
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 80px;
}

.see_i{
  background-color: #fff;
  width: 455px;
  height: 40px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 10px;
}
.btn{
  width: 455px;
  height: 68px;
  /* background-color: #171717; */
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 68px;
  margin-top: 100px;
  margin-bottom: 30px;
  border: 1px solid #fff;
}
.btn:hover{
  background-color: #fff;
  color: #000;
}


.list{
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.list_b{
  /* min-width: 1440px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
}

.list_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 30px;
}

.list_img{
  width: 406px;
  height: 500px;
}

.list_img1{
  width: 424px;
  height: 340px;
}


.list_r{
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.list_num{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  background-color: #EEF2FF;
  color: #000;
  font-size: 20px;
  font-weight: 600px;
}

.list_title{
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-top: 40px;
}
.list_desc{
  margin-top: 10px;
  color: #999;
  font-size: 16px;
  line-height: 36px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 30px;
  font-family: Soehne, system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif;
}
.list_desc p{
  margin: 0px;
  width: 500px;
  text-align: justify;
  word-break:break-word;
}


.video{
  background-color: #000;
  /* height: 1183px; */
  border-top: 1px solid #fff;
  padding-top: 40px;
  padding-bottom: 120px;
}
.video_b{
  /* min-width: 1440px; */
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 60px;
  
}

.video_item{
  border: 1px solid #fff;
  padding: 30px;
  border-radius: 10px;
}





.video_t{
  width: 681px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 120px;
  /* margin-top: 80px; */
}
.video_t div{
  width: 307px;
  flex-shrink: 0;
  text-align: center;
  color: #737373;
  border-bottom: 1px solid #737373;
  padding-bottom: 20px;
  font-size: 26px;
  line-height: 26px;
}
.video_t div:last-child{
  width: 387px;
  font-size: 20px;
}
.video_t .video_check{
  color: #fff;
  border-bottom: 1px solid #fff;

}
.video_desc{
  color: #fff;
  margin-top: 30px;
  /* width: 600px; */
  margin-bottom: 60px;
  font-family: Soehne, system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif;

}
.video_desc p{
  width: 600px;
  text-align: center;
  line-height: 30px;
  text-align: justify;
word-break:break-word;

}
.video_more{
  width: 1240px;
  height: 832px;
  line-height: 832px;
  color: #fff;
  text-align: center;
}

.say{
  background-color: #000;
  border-top: 1px solid #fff;
}
.say_b{
  /* min-width: 1440px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
.say_title{
  color: #eee;
  font-size: 20px;
  margin-top: 40px;
}

.say_desc{
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.say_list{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 80px;
}

.say_item{
  width: 360px;
  height: 258px;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
  -moz-box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
  box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
  -o-box-shadow:0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  transition: all .4s ease-out;
}
.say_item:hover{
  height: 500px;
  transition-timing-function: ease-in;
}

.say_item_t{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.say_item_t img{
  width: 60px;
  height: 60px;
  border-radius: 16px;
}

.say_item_name{
  margin-left: 20px;
  color: #fff;
}
.say_item_title{
  font-size: 18px;
  color: #171717;
  font-weight: 600;
  margin-top: 30px;
}

.say_item_desc{
  font-size: 16px;
  color: #aaa;
  margin-top: 30px;
  display: inline-block;
  font-family:  Signifier, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  line-height: 30px;
  text-align: justify;
  word-break:break-word;
}




.footer{
  width: 100%;
  height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-top: 1px solid #fff;
}

.footer .tabContainer {
  display: flex;
  flex-direction: row;


}
.footer .name{
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
  margin-bottom: 20px;
  
}
.footer .tab{
  width: 50px;
  text-align: center;
  border-radius: 8px;
  margin-top: 10px;
  margin-left: 10px ;
  margin-right: 10px;
}
}

/* 移动端 */
@media screen and (max-width: 768px) {
  .main{
    font-size: 12px;
  }
  .header{
    /* height: auto; */
    height: 460px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .header_a{
    display: flex;
    height: 460px;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.6);
  }
  .header_b{
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .header_img{
    position: absolute;
    left: 0;
    top: 0;
  }
  .header_img img{
    object-fit:fill;
  }
  .header_video{
    width: 100vw;
    height: 460px;
    transition-duration: .3s;
    transition-property: opacity;
    transition-timing-function:cubic-bezier(.4,0,.2,1);
    opacity:1;
    object-fit:cover;
    
  }
  
  .header .tabContainer {
    display: flex;
    flex-direction: row;
  }
  .header .name{
    color: #fff;
    font-weight: bold;
    font-size: 26px;
  }
  
  .header .tab{
    text-align: center;
    border-radius: 8px;
    border: 1px solid #fff;
    overflow: hidden;
    padding: 6px 8px;
    margin-left: 4px;
  }
  .header .tab a{
    display: inline-block;
    /* padding: 8px 0; */
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-bottom: none;
    text-decoration:none;
  }
  
  .header .tab a:hover{
    background-color: #fff;
    color: #000;
  }
  
  .header .tab:first-child{
    /* margin-right: 5px; */
  }
  
  .see_b{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  
  .intro{
    font-weight: 600;
    color: #fff;
  
  }
  .see_t{
    font-weight: 800;
    color: #fff;
    font-size: 24px;
  }
  .see_tb{
    font-weight: 800;
    background: linear-gradient(to right, rgb(80, 107, 193), hsl(298, 27%, 50%));
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block;
    font-size: 24px;
    margin-top: 6px;
  }
  .enjoy_t{
    color: #fff;
    width: 85%;
    margin-top: 30px;
    line-height: 22px;
    text-align: justify;
    word-break:break-word;
  }
  .enjoy_tb{
    color: #fff;
    width: 85%;
  }
  
  .enter{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    /* margin-top: 80px; */
  }
  
  .see_i{
    background-color: #fff;
    /* width: 455px; */
    /* height: 40px; */
    border: 1px solid #eee;
    border-radius: 4px;
  }
  .btn{
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    border: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 10px 20px;

  }
  .btn:hover{
    background-color: #fff;
    color: #000;
  }
  
  
  .list{
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  
  .list_b{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .list_item{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list_item:nth-child(2){
    flex-direction: column-reverse;
  }
  
  .list_img{
    width: 70%;
    display: inline-block;
    margin-top: 30px;
  }
  
  .list_img1{
    margin-top: 30px;
    width: 70%;
    display: inline-block;
  }
  
  
  .list_r{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .list_num{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    background-color: #EEF2FF;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;

  }
  
  .list_title{
    color: #fff;
    /* font-size: 36px; */
    font-weight: 600;
    margin-top: 20px;
  }
  .list_desc{
    margin-top: 10px;
    color: #999;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: Soehne, system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif;
    margin-top: 10px;
  }
  .list_desc p{
    margin: 0px;
    text-align: justify;
word-break:break-word;
  }
  
  
  .video{
    background-color: #000;
    border-top: 1px solid #fff;
  }
  .video_b{
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .video_item{
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  
  .video_t{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .video_t div{
    flex-shrink: 0;
    text-align: center;
    color: #737373;
    border-bottom: 1px solid #737373;
    font-size: 26px;
    width: 150px;
  }
  .video_t div:last-child{
    font-size: 20px;
    display: none;
  }
  .video_t .video_check{
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .video_desc{
    color: #fff;
    margin-top: 20px;
    line-height: 24px;
    font-family: Soehne, system-ui, -apple-system, "Helvetica Neue", Arial, sans-serif;
  }
  .video_desc p{
    text-align: justify;
    word-break:break-word;
    /* line-height: 30px; */
  }
  .video_more{
    color: #fff;
    text-align: center;
  }
  
  .say{
    background-color: #000;
    border-top: 1px solid #fff;
  }
  .say_b{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:30px 20px;
  }
  .say_title{
    color: #eee;
    font-size: 20px;
    margin-top: 10px;
  }
  
  .say_desc{
    color: #fff;
    font-weight: 600;
    margin-top: 20px;
    font-size: 22px;
  }
  
  .say_list{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  
  .say_item{
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
    -webkit-box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
    -moz-box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
    box-shadow: 0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
    -o-box-shadow:0 1px 4px rgba(255,255,255,0.6),0 0 40px rgba(255,255,255,0.3) inset;
    font-family: Inter, "Helvetica Neue", Arial, sans-serif;
    transition: all .4s ease-out;
    margin-top: 30px;
    width: 90%;
    padding: 20px;
    line-height: 24px;
  }
  .say_item:hover{
    transition-timing-function: ease-in;
  }
  
  .say_item_t{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .say_item_t img{
    width: 50px;
    border-radius: 16px;
    margin-right: 20px;
  }
  
  .say_item_name{
    color: #fff;
  }
  .say_item_title{
    font-size: 18px;
    color: #171717;
    font-weight: 600;
  }
  
  .say_item_desc{
    font-size: 16px;
    color: #aaa;
    text-align: justify;
    word-break:break-word;
    display: inline-block;
    font-family:  Signifier, ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  
  .footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000;
    border-top: 1px solid #fff;
    padding:30px 20px;
  }
  
  .footer .tabContainer {
    display: flex;
    flex-direction: row;
   
  }
  .footer .name{
    font-size: 20px;
    color: #fff;
  }
  .footer .tab{
    text-align: center;
    border-radius: 8px;
    margin: 20px 10px;
  } 
}

.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 10px;
  line-height: 20px;
  border-radius: 5px;
  background-color: #fff;
  color: #67c23a;
  border: 1px solid #67c23a;
  box-shadow: 0 2px 12px 0 rgba(103,194,58, 0.1);
  opacity: 1;
  transition: opacity 0.5s ease;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:  0 4px;
  border-radius: 4px;
  
}

.toast img{
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.visible {
  opacity: 1;
}

